import { graphql } from "gatsby"
import React from "react"

import HomeBannerComponent from "./HomeBanner"

export const fragment = graphql`
  fragment HomeBannerFragment on WpPage_Flexlayouts_FlexibleLayouts_HomeBanner {
    textBox {
      pageTitle
      description
      button {
        title
        url
        target
      }
    }
    imageBox {
      largeTitle {
        lineOne
        lineTwo
      }
      subTitle
      backgroundImage {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 1109
              height: 617 
              quality: 75
              placeholder: NONE
            )
          }
        }
      }
      foregroundImage {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 777
              height: 661
              quality: 75
              placeholder: NONE
            )
          }
        }
      }
      
    }
  }
`

export const ACFHomeBanner = ({ textBox, imageBox }) => (
  <HomeBannerComponent
    textBox={textBox}
    imageBox={imageBox}
   />
)
