import { Link } from "../../UI/Common"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col, Button } from "react-bootstrap"

import {
  headerClass,
  imageWrapperCls,
  imgTitleLine1Cls,
  doubleLineTitleCls,
  imgTitleLine2Cls,
  doubleLineTitleSubCls,
  imgSubTitleCls,
  bgImageCls,
  foreImageWrapperCls,
  textBoxClass,
  linkCls
} from "./HomeBanner.module.scss"
import {createLocalLink} from "../../../utils";

const HomeBanner = ({ textBox, imageBox }) => {
  const { pageTitle, description, button } = textBox

  const { backgroundImage, foregroundImage, largeTitle: title , subTitle } = imageBox

  let line1Styles = imgTitleLine1Cls
  let subTitleStyles = imgSubTitleCls

  if(Object.keys(title).length > 1) {
    line1Styles = `${imgTitleLine1Cls} ${doubleLineTitleCls}`
    subTitleStyles = `${imgSubTitleCls} ${doubleLineTitleSubCls}`
  }

  return (
    <header className={headerClass}>
      <div className="padded-component">
        <Row>
          <Col xs={12} xl={7}>
            <div className={imageWrapperCls}>
              {backgroundImage && (
                <GatsbyImage
                  className="h-100"
                  imgClassName={bgImageCls}
                  alt={backgroundImage.altText}
                  image={getImage(backgroundImage.localFile)}
                />
              )}
                <div className="d-none d-md-block">
                  <div className={line1Styles}>{title.lineOne}</div>
                  {title.lineTwo && (
                    <div className={imgTitleLine2Cls}> {title.lineTwo}</div>
                  )}
                </div>
              {foregroundImage && (
                <GatsbyImage
                  className={foreImageWrapperCls}
                  style={{ position: "absolute" }}
                  objectFit="scale-down"
                  alt={backgroundImage.altText}
                  image={getImage(foregroundImage.localFile)}
                />
              )}
              <div className={`d-none d-md-block ${subTitleStyles}`}>{subTitle}</div>
            </div>
          </Col>
          <Col xs={0} xl={1} />
          <Col xs={12} xl={4} xxl={3}>
            <div className={textBoxClass}>
              <h1 className="py-3">{pageTitle}</h1>
              <div className="mt-3 mb-5 fs-lg-4">{description}</div>
              <Link className={linkCls} link={createLocalLink(button.url)} target={button.target}>
                <Button size="lg">{button.title}</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </header>
  )
}

export default HomeBanner
