// extracted by mini-css-extract-plugin
export var bgGrey100 = "HomeBanner-module--bg-grey-100--5d4a2";
export var bgGrey150 = "HomeBanner-module--bg-grey-150--30e52";
export var bgGrey200 = "HomeBanner-module--bg-grey-200--fad74";
export var bgGrey300 = "HomeBanner-module--bg-grey-300--2b492";
export var bgGrey400 = "HomeBanner-module--bg-grey-400--f5521";
export var bgGrey500 = "HomeBanner-module--bg-grey-500--69ca7";
export var bgGrey600 = "HomeBanner-module--bg-grey-600--fb730";
export var bgGrey700 = "HomeBanner-module--bg-grey-700--7b7a4";
export var bgGrey800 = "HomeBanner-module--bg-grey-800--a68fc";
export var bgGrey900 = "HomeBanner-module--bg-grey-900--893b8";
export var bgImageCls = "HomeBanner-module--bgImageCls--03baa";
export var doubleLineTitleCls = "HomeBanner-module--doubleLineTitleCls--bd54f";
export var doubleLineTitleSubCls = "HomeBanner-module--doubleLineTitleSubCls--f2d31";
export var foreImageWrapperCls = "HomeBanner-module--foreImageWrapperCls--614f6";
export var headerClass = "HomeBanner-module--headerClass--54aa3";
export var imageWrapperCls = "HomeBanner-module--imageWrapperCls--f71c4";
export var imgSubTitleCls = "HomeBanner-module--imgSubTitleCls--d1057";
export var imgTitleLine1Cls = "HomeBanner-module--imgTitleLine1Cls--79b03";
export var imgTitleLine2Cls = "HomeBanner-module--imgTitleLine2Cls--fe821";
export var linkCls = "HomeBanner-module--linkCls--06c19";
export var textBoxClass = "HomeBanner-module--textBoxClass--85fd5";
export var textGrey100 = "HomeBanner-module--text-grey-100--ec25d";
export var textGrey150 = "HomeBanner-module--text-grey-150--04213";
export var textGrey200 = "HomeBanner-module--text-grey-200--183e9";
export var textGrey300 = "HomeBanner-module--text-grey-300--d4bc8";
export var textGrey400 = "HomeBanner-module--text-grey-400--588c8";
export var textGrey500 = "HomeBanner-module--text-grey-500--bc02e";
export var textGrey600 = "HomeBanner-module--text-grey-600--b0019";
export var textGrey700 = "HomeBanner-module--text-grey-700--e218c";
export var textGrey800 = "HomeBanner-module--text-grey-800--7898b";
export var textGrey900 = "HomeBanner-module--text-grey-900--1cfc7";